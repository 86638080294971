import React, { useState, useEffect, useRef } from 'react';
import { Form, Card, Input, Button, Checkbox, ConfigProvider, Result, InputNumber, Select, DatePicker, notification, Divider } from 'antd';
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { CheckCircleFilled, InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import aseguradoras from '../data/aseguradora';
import changeHeight from '../data/changeHeight';
import { getOverflowOptions } from 'antd/es/_util/placements';

const FormBlogPrimaUnica = () => {
    const [buttonLoading, setbuttonLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const refResult = useRef(0);
    const [result, setResult] = useState("collapse");
    const [heightResult, setHeightResult] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [subtitleText, setSubtitleText] = useState("Tu información ha sido enviada correctamente.<br/>En breve nos pondremos en contacto contigo, pero recuerda que puedes concertar una cita haciendo clic en el siguiente botón.");


    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
            setResult("none");
            setHeightResult(refResult.current.clientHeight);
        }
        if (searchParams.get("parentUrl")) {
            form.setFieldsValue({ url: searchParams.get("parentUrl") });
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refForm.current);

        return () => {
            resizeObserver.unobserve(refForm.current);
        };
    }, []);

    window.addEventListener('message', (event) => {
        if (event.data.type === "from-parent") {
            if (event.data.fbp === 'null' || event.data.fbp === null || event.data.fbp === undefined) {
                form.setFieldsValue({ fbp: " " });
            } else {
                form.setFieldsValue({ fbp: event.data.fbp });
            }
            if (event.data.hubspotutk === 'null' || event.data.hubspotutk === null || event.data.hubspotutk === undefined) {
                form.setFieldsValue({ hubspotutk: " " });
            } else {
                form.setFieldsValue({ hubspotutk: event.data.hubspotutk });
            }
            if (event.data.userAgent) { form.setFieldsValue({ userAgent: event.data.userAgent }); }
            changeHeight(refForm.current.clientHeight);
        }
    });

    const onFinish = (values) => {
        setbuttonLoading(true);
        axios.post(`${process.env.REACT_APP_URL_PRIMA_UNICA}`, values)
            .then(function (response) {
                if (response.data.message === "OK") {
                    setbuttonLoading(false);
                    setSuccess(true);
                    setResult("block");
                    if (!values.whatsapp) {
                        setSubtitleText("Tu información ha sido enviada correctamente.<br/>En breve serás redirigido a nuestro WhatsApp para que puedas preguntarnos cualquier duda que tengas.");
                        setTimeout(() => {
                            var ventana = window.parent;
                            ventana.postMessage('{"type":"redirect-whatsapp"}', '*');
                        }, 5000);
                    }
                    changeHeight(heightResult);
                }
            })
            .catch(function () {
                openNotification('bottom');
                setbuttonLoading(false);
            });
    };

    const disabledDateBeforeToday = (current) => {
        // Can not select days before today, today yes
        return current && current > dayjs().endOf('day');
    };

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description:
                'Ha ocurrido un error inesparado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    const customParser = (value) => {
        // Reemplaza comas por puntos
        const processedValue = value.replace(/,/g, '.');
      
        // Analiza el valor en un número de punto flotante
        const parsedValue = parseFloat(processedValue);
      
        // Asegúrate de que el resultado es un número
        if (!isNaN(parsedValue)) {
          return parsedValue;
        }
      
        return undefined; // Si no se pudo analizar, devuelve undefined
      };

    return (

        <>
            {contextHolder}
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>
                <div ref={refForm}>
                    <Card 
                        title="Formulario para iniciar tu reclamación"
                        style={{ width: '100%', backgroundColor: "#f0f2f5" }}
                        headStyle={
                            {
                                backgroundColor: primaryColor,
                                color: "white"
                            }
                        }
                    >
                        <div>
                            <Form
                                form={form}
                                style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
                                hidden={success}
                                name="basic"
                                initialValues={{ remember: true }}
                                layout='vertical'
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    hidden={true}
                                    label="URL"
                                    name="url"
                                >
                                    <Input type='text' />
                                </Form.Item>

                                <Form.Item
                                    hidden={true}
                                    label="FB"
                                    name="fbp">
                                    <Input type='' />
                                </Form.Item>

                                <Form.Item
                                    hidden={true}
                                    label="Hubspotutk"
                                    name="hubspotutk">
                                    <Input type='' />
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cúanto pagaste por tu seguro de prima única?</p>}
                                    name="prima_unica"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, ingresa un valor' }]}
                                >
                                    <InputNumber style={{ width: '100%' }} placeholder='19000' disabled={buttonLoading} parser={customParser} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuál es tu Aseguradora?</p>}
                                    name="compania"
                                    rules={[{ required: true, message: 'Por favor, escoge tu banco o aseguradora' }]}
                                >
                                    <Select showSearch placeholder='Escoge tu banco o aseguradora' disabled={buttonLoading}>
                                        {aseguradoras.map((aseguradora) => (
                                            <Select.Option key={aseguradora} value={aseguradora}>{aseguradora}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuándo pagaste el seguro de vida?</p>}
                                    name="fecha_pago"
                                    rules={[{ required: true, message: 'Por favor, ingresa una fecha' }]}
                                    tooltip={{ title: 'Suele coincidir con el inicio de la hipoteca', icon: <InfoCircleOutlined /> }}
                                >
                                    <DatePicker placeholder='dd/mm/yyyy' style={{ width: '100%' }} disabledDate={disabledDateBeforeToday} locale={locale} disabled={buttonLoading} format="DD/MM/YYYY" />
                                </Form.Item>

                                {/* <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuál es la duración del seguro?</p>}
                                    name="duracion_seguro"
                                    rules={[{ required: true, message: 'Por favor, ingresa la duración del seguro' }]}
                                >
                                    <Input placeholder='15 años' disabled={buttonLoading} type='text' />
                                </Form.Item> */}

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos enviar un email?</p>}
                                    name="email"
                                    rules={[{ required: true, message: 'Por favor, ingresa tu email' }]}
                                >
                                    <Input placeholder='tunombre@email.com' type='email' disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde podemos llamarte?</p>}
                                    name="tel"
                                    rules={[{ required: true, message: 'Por favor, ingresa tu teléfono' }]}
                                >
                                    <Input placeholder='919 99 31 31' type='tel' disabled={buttonLoading} maxLength={9} minLength={9} />
                                </Form.Item>

                                <Form.Item style={{ marginBottom: "0rem" }} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta los términos y condiciones")); } } }]}>
                                    <Checkbox style={{ fontSize: "13px" }} disabled={buttonLoading} >Acepto los <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>términos y condiciones</Link></Checkbox>
                                </Form.Item>

                                <Form.Item style={{ marginBottom: "0.6rem" }} valuePropName="checked" name="whatsapp" initialValue={false}>
                                    <Checkbox style={{ fontSize: "13px" }} disabled={buttonLoading} >Acepto recibir asesoramiento vía WhatsApp</Checkbox>
                                </Form.Item>
                                
                                <Form.Item>
                                    <Button type='primary' loading={buttonLoading} htmlType="submit" style={{ width: '100px', height: '40px', marginLeft: '23px' }}>
                                        {buttonLoading ? 'Enviando...' : 'Enviar'}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div ref={refResult}>
                            <div style={{ display: `${result}` }}>
                                <Result
                                    icon={<CheckCircleFilled style={{ color: primaryColor }} />}
                                    style={{ padding: '1.5rem', paddingTop: '3rem' }}
                                    status="success"
                                    title="¡Gracias!"
                                    subTitle={<span dangerouslySetInnerHTML={{ __html: subtitleText }} />}
                                    extra={[
                                        <Button type="primary" key="console" target='_blank' href='https://meetings-eu1.hubspot.com/santiago-gatti'>
                                            Haz clic para concertar una cita
                                        </Button>
                                    ]}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </ConfigProvider >

        </>
    );
}

export default FormBlogPrimaUnica;